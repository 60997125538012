@keyframes spin-reverse {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.clickedss {
    animation: spin-reverse 1s linear infinite;
}